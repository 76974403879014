import React, { useState, useRef, useEffect } from 'react';
import {toPound} from "./globals";

export default function Detail({item, x, y,ome, omo}:{item:ItemAll|null, x:number, y:number, omo: (q:string)=>void, ome:(q:string)=>void }) {

  function getRows(){



    var rows:any = [];
    Object.entries(item!).forEach( (x)=> {
     // console.log(x[1]);

      if ( typeof x[1] == 'number' && !['_id', 'quantity', 'url', 'name', 'market', 'caloTotal','breadcrumb'].includes(x[0]) ) {

        if (x[0] == 'price') {

          rows.push(<tr>
            <td>price</td>
            <td>{toPound(x[1])}</td>
          </tr>);
        } else {

          var small = x[0] == 'cal' ? 'kcal' : 'g';
          rows.push(<tr>
            <td>{x[0] == 'cal' ? 'energy' : x[0]}</td>
            <td>{x[1]}<small>{small}</small></td>
          </tr>);
        }
      }
    });

    //var table = rows.join('');
//      console.log(table);
    return <tbody>{rows}</tbody>
  }

  return (item && <div id={'detail'} onMouseEnter={()=>{ome('d')}} onMouseLeave={()=>omo('d')} style={{left:x, top:y}}>

    <h2>{item.name}</h2>
    <table>
      <tr>
        <td></td>
        <td><small>per 100g</small></td>
      </tr>
      {getRows()}
      {item.url ? <tr>
        <td style={{textAlign:"center"}} colSpan={2}><a target="_blank" href={item.url}>show item on {item.market}</a></td>
      </tr> : ''}
    </table>

  </div>
 )
}












