import React, { useState, useRef } from 'react';
import styled from "styled-components";
import RecipeTable from "./RecipeTable"
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import recipe from "./recoil"
import recipeAtom from "./recoil";
import { GoSearch } from 'react-icons/go';



/*
const Suggestion = styled.div`
  padding: 5px;
  border: solid 1px grey;
  margin: 10px;
  background-color: greenyellow;
  color: black;
  cursor: crosshair;
`*/


export default function Search(){

    const [recipe, setRecipe] = useRecoilState(recipeAtom);
    const [suggestions, setSuggestions] = useState([]);
    const searchField = useRef<HTMLInputElement>(null);
    const [showExplanation, setShowExplanation] = useState(true);
    const [removeExplanation, setRemoveExplanation] = useState(false);
    var timer:{current: ReturnType<typeof setTimeout>|null} = useRef(null);

    const input = (value:string) => {

      if (showExplanation) {
        //setShowExplanation(false);
        //setTimeout( ()=>{setRemoveExplanation(true)}, 1200);
      }

       if (value.length>3){

            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(()=>{

                fetch(`https://api.foodcalculator.uk/food/${searchField.current?.value}`,{mode: 'cors',})
                  .then((response) => response.json())
                  .then((data) => {
                      setSuggestions(data.response);
                      timer.current = null;
                  })
                },800);
    }
    }


    const selected = (i:number) => {

        if (typeof suggestions[i] == "object") {

            var r = [...recipe];

            // @ts-ignore
            var added = {quantity:0,caloTotal:0,...suggestions[i]};

            // @ts-ignore
            r.push(added);
            setRecipe(r);
            setSuggestions([]);
            if (searchField.current) searchField.current.value = "";
        }
    }

    return <>


      <div className={'topText'} >
        <h1>Calculate the calories for a recipe.</h1>
      </div>
        <div className={"search_input"} >
          <label>Add ingredients to your recipe:</label>
          <GoSearch size={"1.6em"} style={{position:"absolute",marginTop:"10px",marginLeft:"8px"}}/>
          <input ref={searchField} onInput={(e:React.ChangeEvent<HTMLInputElement>)=>{input(e.target?.value)}} />
        </div>
        <div className={'flexCenter'}>
        { (suggestions.length>0) && suggestions.map((r:ItemAll,i)=><div className={"suggestion"} onClick={()=>{selected(i)}}>{r.name}</div>)}
        </div>

    </>
}
