import React, { useState, useRef, useEffect } from 'react';

type CookieConsent = {
  callback: ()=> void
}

export default function CookieConsent({callback}:CookieConsent) {

  return <div id={'cookieConsent'}>
    <p>This website is using cookies, but only low-calories ones. </p>

    <div onClick={callback}><div>I'm cool with that!</div></div>

  </div>

}
