import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';



const recipeAtom = atom<ItemAll[]>({
    key: 'recipe', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export default recipeAtom;