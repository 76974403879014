import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import itemState from "./recoil"
import {toPound} from "./globals";



export default function MealBreakDown({calories, weight, price, protein, carbs, fat}:{calories:number, weight:number, price:number, protein:number, carbs:number, fat:number}) {

    const [p, setP] = useState(4);
    const [wt, setWT] = useState(weight);
    const [wo, setWO] = useState(weight);
    const [co, setCO] = useState(calories);
    const [wp, setWP] = useState(Math.round(weight/4));
    const [c, setC] = useState(Math.round(calories/4));

    const userInput = (param:PortionParamsKeys, val:string|number) => {

        var v = Number(val);

       if ( !isNaN(v) && (wt > 0 || param == "weightTotal" )) {

           if ( param == "portions" ) {

               //if ( v < 1 ) v = 1;
                setP(v);
                setWP(Math.round(wt / v));
                setC(Math.round(calories / v));

           } else if ( param == "weightTotal" ) {

               setWT(v);
               const wpi = Math.round(v / p)
               setWP(wpi);

           } else if ( param == "weightPortion" ) {

               if ( v > wt ) v = wt;

               setWP(v);
               const pi = Math.round( wt / v * 10 ) / 10;
               setP(pi);
               setC(Math.round(calories / pi));

           } else if ( param == "calories" ) {

               v = ( v > calories ) ? calories : v;
               setC(v);
               const pi = Math.round( calories / v * 10 ) / 10;
               setP(pi);
               setWP(Math.round(wt / pi));
           }
       }
    }


    useEffect(()=>{

        if ( wo != weight ) {

          userInput('weightTotal', weight);
          setWO(weight);
        }

        if ( co != calories ) {

          setC( Math.round( calories / p ) );
        }

    })


    return <div className={"mealBreakDown"}>

        <div>
          <label>Weight after cooking:</label>
          <div className={'inputFieldFrame'}>
            <input value={wt} size={5} onInput={ (e:React.ChangeEvent<HTMLInputElement>) => userInput("weightTotal", e.target.value)} />
          </div>
        </div>
        <div>
          <label>Portions:</label>
          <div className={'inputFieldFrame'}>
            <input value={p == Infinity ? '' : p} size={5} onInput={ (e:React.ChangeEvent<HTMLInputElement>) => userInput("portions", e.target.value)} />
          </div>
        </div>
        <div>
          <label>Weight per portion:</label>
          <div className={'inputFieldFrame'}>
            <input value={wp == Infinity ? '' : wp} size={5} onInput={ (e:React.ChangeEvent<HTMLInputElement>) => userInput("weightPortion", e.target.value)} />
          </div>
        </div>
        <div>
          <label>calories per portion:</label>
          <div className={'inputFieldFrame'}>
            <input value={c == Infinity ? '' : c} size={5} onInput={ (e:React.ChangeEvent<HTMLInputElement>) => userInput("calories", e.target.value)} />
          </div>
        </div>
      <div>
        <label>fat per portion:</label>
        <div className={'inputFieldFrame'}>
          <input style={{pointerEvents:'none'}} value={ p == 0 ? '' : Math.round(fat/p)} size={5} />
        </div>
      </div>
      <div>
        <label>carbs per portion:</label>
        <div className={'inputFieldFrame'}>
          <input style={{pointerEvents:'none'}} value={ p == 0 ? '' : Math.round(carbs/p)} size={5} />
        </div>
      </div>
        <div>
          <label>Protein per portion:</label>
          <div className={'inputFieldFrame'}>
            <input style={{pointerEvents:'none'}} value={ p == 0 ? '' : Math.round(protein/p)} size={5} />
          </div>
        </div>
        <div>
          <label>Price per portion:</label>
          <div className={'inputFieldFrame'}>
            <input style={{pointerEvents:'none'}} value={ p == 0 ? '' : toPound(price/p)} size={5} />
          </div>
        </div>

        <div id={'mealBreakDown__explanation'}>
          <p>In case the weight of your dish has changed after preparation (because you have added water or cooked it), specify its weight after cooking.<br/><br/>
          You can either change the number of portions, or the weight of each portion, or the amount of calories per portion.<br/><br/>
            For example, your goal is to prepare a meal with 600 kcal, set the field calories per portion to 600, and the weight of a portion with that amount of food will be calculated. </p>

        </div>
    </div>
}

/*
        <PortionParamInput p="portions" />
        <br />
        <label>Weight per portion:</label>
        <PortionParamInput p="weightPortion" />
        <br />
        <label>Calories per portion:</label>
        <PortionParamInput p="calories" />

        <PortionParamInput p="weightTotal" />
 */
