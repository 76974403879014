import React, {useState} from 'react';
import './App.css';
import styled from "styled-components";
import ItemInput from "./ItemInput";
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import itemState from "./recoil";

import Search from "./Search";
import RecipeTable from "./RecipeTable";
import CookieConsent from "./Cookie";

const Button = styled.button`
  background-color: #61dafb;
  border: 1px black solid;
  border-radius: 4px;
`

const cookieSet = localStorage.cookie

function App() {

  const [cookie, setCookie] = useState(cookieSet);



  return (
    <div className="App">
        <RecoilRoot>
            <Search />
            <RecipeTable />
        </RecoilRoot>


      <div id={'feedback'}>feedback, questions? &nbsp;&nbsp;&nbsp;<a href={"mailto: christoph@schellhaus.co"}>christoph@schellhaus.com</a></div>
      {!cookie && <CookieConsent callback={()=>{setCookie(true); localStorage.cookie=true}}/>}
    </div>
  );
}

export default App;
