import {useRecoilState} from "recoil";
import itemState from "./recoil";
import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import recipeAtom from "./recoil";

import MealBreakDown from "./MealBreakDown";
import Detail from "./detail";
import {toPound} from "./globals";

/* css----------------------------------------
const QuantityInput = styled.input`
  background-color: aqua;
  text-align: right;
  padding: 10px;
  width: 100px;
  border-style: none;
  &:focus {
    background-color: greenyellow;
    outline: 10px red solid;
  }
`

const Table = styled.table`
    & td:not(:first-child) {
      background-color: aqua;
      text-align: right;
      width: 200px
    }
    & td:first-child {
      background-color: yellow;
      text-align: right;
      width: 800px
    }
    & tr:last-child td {
      background-color: yellow;
      text-align: right;
      font-weight: bold;
      width: 200px
    }
    & tr:first-child th {
      font-weight: normal;
      text-align: center;
      font-size: 12px;
      width: 200px;
      & b {
        font-size: 24px;
        color: orangered;
      }
    }
`

//--------------------------------------------------------------------
*/

export default function ItemTable() {

    const totalsInit = {
        quantity:0,
        fat:0,
        saturates:0,
        protein:0,
        sugar:0,
        fibre:0,
        carbs:0,
        cal:0,
        salt:0,
        price:0,
    };

    const [recipe, setRecipe] = useRecoilState(recipeAtom);
    const [total, setTotal] = useState<ItemNum>(totalsInit);
    const [lastItem, setLastItem] = useState(0);
    const [detailItem, setDetailItem] = useState(null);
    const detailHover = useRef(0);
    var mousePos = useRef([0,0]);

    const timer:{current:ReturnType<typeof setTimeout>|null|undefined} = useRef();

    const itemNumKeys:ItemNumKeys[] = [
        "quantity",
        "fat",
        "saturates",
        "protein",
        "sugar",
        "fibre",
        "carbs",
        "cal",
        "salt",
        "price"
    ];

    // absolut sum:
    const abs = (param: ItemNumKeys, index:number) => {

        return Math.round((recipe[index][param] || 0) * (recipe[index].quantity  || 0) / 100);
    }

    // total absolut sum:
    const absTot = (param: ItemNumKeys) => {

        let a=0;
        for ( var i=0; i< recipe.length; i++) {
            a+= abs(param, i);
        };
        return a;
    }


    const calculateTotals = (r:ItemNum[]) => {

        var t:ItemNum = totalsInit;
        //var i: keyof typeof itemNumKeys;
        itemNumKeys.forEach( (param:ItemNumKeys) => {

            let a=0;

              for ( var i=0; i< r.length; i++) {
                a+= Math.round((param != 'quantity') ? (r[i][param] || 0) * (r[i].quantity  || 0) / 100 : r[i].quantity || 0);
              }

            t[param] = a;
        } );

        setTotal(t);
    }


    const quantityInput = (val:string, i:number) => {

        var q = Number(val.replace(/\D/, ''));
        q = q>9999 ? 9999 : q;
            let replica: ItemAll[] = [];
            for (let r of recipe) {

                let newItem: any = {};

                let i: keyof typeof r;
                for (i in r) {

                    newItem[i] = r[i];
                }
                replica.push(newItem);

            }


            replica[i].quantity = q;

            //replica[i].cal = Math.round((replica[i].quantity || 0) * replica[i].cal / 100);
            calculateTotals(replica);
            setRecipe(replica);
        }

        useEffect( ()=>{

          if ( recipe.length > lastItem ) {

            setLastItem(recipe.length);
          }
        })

    const show_detail = (i:any) => {

            timer.current = setTimeout( ()=>{
              setDetailItem(i);
            }, 500);
        }

        type Func= (q:string) => void

        const in_detail:Func = (q) => {

          if ( timer.current ) {
            clearTimeout(timer.current);
            timer.current = null;
          }

          detailHover.current += 1;
          console.log(detailHover.current);
    }

    const out_detail:Func = (q) => {

      if (detailHover.current == 1) {

        timer.current = setTimeout(()=>setDetailItem(null),300);
      }
      if (detailHover.current > 0) detailHover.current -= 1;

      console.log(detailHover.current);
    }


        const SumUps = () => {

        return <>
            <tr>
                <td>Total</td>
                <td>{total.quantity}</td>
                <td>{total.cal}</td>
                <td>{total.fat}</td>
                <td>{total.protein}</td>
                <td>{total.carbs}</td>
                <td>{total.fibre}</td>
                <td>{total.salt}</td>
                <td>{toPound(total.price)}</td>
            </tr>
            </>
        }

    return <>

      { (recipe.length > 0) && <table className={'recipeTable'}>

        <thead>
        <tr>
            <th></th>
            <th><b>quantity</b><br /> in gram</th>
            <th><b>calories</b><br /> kcal</th>
            <th><b>fat</b><br /> in gram</th>
            <th><b>proteins</b><br /> in gram</th>
            <th><b>carbs</b><br /> in gram</th>
            <th><b>fibre</b><br /> in gram</th>
            <th><b>salt</b><br /> in gram</th>
            <th><b>price</b><br /> in gram</th>

        </tr>
        </thead>
        <tbody>

        {recipe.map( (i:ItemAll, index) => <tr key={i.name}>
          <td onMouseMove={({pageX, pageY})=>mousePos.current=[pageX, pageY]} onMouseOver={()=>show_detail(i)} onMouseEnter={()=>in_detail('i')} onMouseOut={()=>out_detail('i')}>{i.name} </td>
          <td><input autoFocus={ index == lastItem } size={5} value={ (i.quantity && i.quantity>0? i.quantity : '')} onInput={(e:React.ChangeEvent<HTMLInputElement>)=>{quantityInput(e.target?.value, index)}}/> </td>
          <td>{abs('cal', index)}</td>
          <td>{abs('fat', index)}</td>
          <td>{abs('protein', index)}</td>
          <td>{abs('carbs', index)}</td>
          <td>{abs('fibre', index)}</td>
          <td>{abs('salt', index)}</td>
          <td>{ toPound(abs('price', index)) }</td>
        </tr>)}

        <SumUps />
        </tbody>
    </table>}

      { (total.quantity && (total.quantity > 0)) ? <MealBreakDown weight={total.quantity||0} calories={total.cal || 0} fat={total.fat || 0} carbs={total.carbs || 0} protein={total.protein || 0} price={total.price}/> : ''}
      {detailItem && <Detail item={detailItem} ome={in_detail} omo={()=>out_detail('d')} x={mousePos.current[0]} y={mousePos.current[1]} />}
    </>

}
